.sf-input__wrapper {
  position: relative;
}
.sf-input__wrapper *, .sf-input__wrapper *::before, .sf-input__wrapper *::after {
  box-sizing: border-box;
}

.adyen-checkout__input {
  display: block;
  max-height: 100px;
}