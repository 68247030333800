.adyen-checkout__payment-methods-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.adyen-checkout__payment-method {
  display: block;
  max-height: 60px;
}
.adyen-checkout__payment-method__details {
  display: none;
}
.adyen-checkout__payment-method__image {
  width: 40px;
  height: 26px;
}
.adyen-checkout__payment-method__image__wrapper {
  margin-right: 8px;
}
[dir=rtl] .adyen-checkout__payment-method__image__wrapper {
  margin-right: 0;
  margin-left: 8px;
}
.adyen-checkout__payment-method--selected {
  max-height: 100%;
}
.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__details {
  display: block;
}