.adyen-checkout__qr-loader {
  background: #fff;
  padding: 40px;
  border: 1px solid #d4d9db;
  border-radius: 12px;
  text-align: center;
}

.adyen-checkout__qr-loader--result {
  padding: 100px;
}

.adyen-checkout__qr-loader--app {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.adyen-checkout__qr-loader__brand-logo {
  width: 74px;
  border-radius: 3px;
}

.adyen-checkout__qr-loader__subtitle {
  max-width: 400px;
  margin: 32px auto 0;
}

.adyen-checkout__qr-loader__subtitle--result {
  margin-bottom: 32px;
}

.adyen-checkout__qr-loader__subtitle,
.adyen-checkout__qr-loader__payment_amount {
  color: #00112c;
  font-size: 1em;
  line-height: 19px;
}

.adyen-checkout__qr-loader__icon {
  width: 88px;
  height: 88px;
}

.adyen-checkout__qr-loader__payment_amount {
  font-weight: bold;
}

.adyen-checkout__qr-loader__progress {
  height: 4px;
  background: #d4d9db;
  border-radius: 24px;
  margin: 32px auto 12px;
  width: 152px;
  padding-right: 3%;
}
[dir=rtl] .adyen-checkout__qr-loader__progress {
  padding-right: 0;
  padding-left: 3%;
}

.adyen-checkout__qr-loader__percentage {
  display: block;
  height: 100%;
  border-radius: 24px;
  background: #0075ff;
}

.adyen-checkout__qr-loader__countdown {
  color: #687282;
  font-size: 0.81em;
}

.adyen-checkout__qr-loader > .adyen-checkout__spinner__wrapper {
  margin: 60px 0;
}

.adyen-checkout__qr-loader__app-link {
  display: none;
}

.adyen-checkout__button.adyen-checkout__button--qr-loader {
  text-decoration: none;
  margin-top: 24px;
}

.adyen-checkout__qr-loader__instructions {
  color: #687282;
  font-size: 1em;
  line-height: 1.5;
  margin-top: 32px;
}

.adyen-checkout__qr-loader__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

@media only screen and (max-device-width: 1200px) {
  .adyen-checkout__qr-loader__app-link {
    display: block;
  }
}