.adyen-checkout-ctp__card {
  width: 100%;
  height: 40px;
  margin: 0;
  background-color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 0 0 2px #999595;
  cursor: pointer;
}

.adyen-checkout-ctp__empty-cards {
  border-radius: 6px;
  background-color: #f7f8f9;
  color: #687282;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  padding: 12px 16px;
  margin-bottom: 24px;
}

.adyen-checkout__iframe--ctpIframe {
  pointer-events: auto;
}

.adyen-checkout__iframe--ctpIframe-hidden {
  display: none;
}