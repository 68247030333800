.adyen-checkout__payment-method__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.adyen-checkout__payment-method__name--selected {
  font-weight: 500;
}
.adyen-checkout__payment-method__additional-info {
  font-size: 0.81em;
  color: #687282;
}
.adyen-checkout__payment-method__name_wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}