.adyen-checkout__modal-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  padding: 24px;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 10;
}
.adyen-checkout__modal-wrapper::before {
  background: rgba(0, 17, 44, 0.5);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 10;
}
.adyen-checkout__modal-wrapper--open {
  visibility: visible;
}
.adyen-checkout__modal-wrapper--open::before {
  opacity: 1;
}
.adyen-checkout__modal-wrapper--open .adyen-checkout__modal {
  opacity: 1;
}

.adyen-checkout__modal {
  box-shadow: 0 8px 24px rgba(0, 17, 44, 0.15);
  border-radius: 12px;
  background-color: #fff;
  margin: auto;
  padding: 16px;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  opacity: 0;
  position: relative;
  z-index: 11;
}

@media (max-width: 480px) {
  .adyen-checkout__modal-wrapper {
    padding: 0;
  }
  .adyen-checkout__modal {
    border-radius: 0;
    height: 100%;
  }
}