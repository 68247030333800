.adyen-checkout__spinner__wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adyen-checkout__spinner__wrapper--inline {
  height: auto;
  display: inline-block;
  margin-right: 8px;
}
[dir=rtl] .adyen-checkout__spinner__wrapper--inline {
  margin-right: 0;
  margin-left: 8px;
}

.adyen-checkout__spinner {
  border: 3px solid #0075ff;
  border-radius: 50%;
  height: 43px;
  width: 43px;
  border-top-color: transparent;
  animation: rotate-spinner 1.5s infinite linear;
}

.adyen-checkout__spinner--large {
  height: 43px;
  width: 43px;
}

.adyen-checkout__spinner--small {
  height: 16px;
  width: 16px;
  border-width: 2px;
}

.adyen-checkout__spinner--medium {
  height: 28px;
  width: 28px;
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}