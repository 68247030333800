.adyen-checkout__card__dual-branding__buttons {
  display: flex;
  opacity: 0.4;
  pointer-events: none;
}
.adyen-checkout__card__dual-branding__buttons--active {
  opacity: 1;
  pointer-events: auto;
}
.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon {
  cursor: pointer;
  opacity: 1;
}
.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon:nth-child(1) {
  right: 40px;
}
.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon--not-selected {
  opacity: 0.5;
}