.adyen-checkout-phone-input--new {
  direction: ltr;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper {
  width: 100%;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input {
  padding: 0;
  height: auto;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input:focus-within {
  border: 1px solid #0075ff;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input:focus-within .adyen-checkout-dropdown--countrycode-selector {
  border-right: 1px solid #0075ff;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button {
  width: auto;
  border: 0;
  height: 35px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button::after {
  box-sizing: revert;
  height: 10px;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active {
  box-shadow: none;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active:hover {
  box-shadow: none;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input--phone-number {
  height: 35px;
  line-height: 35px;
  min-height: 35px;
  padding-bottom: 0;
  padding-top: 0;
  border: 1px solid transparent;
  padding-left: 15px;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input--phone-number:focus-within {
  border: 1px solid #0075ff;
  box-shadow: 0 0 0 2px #99c2ff;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-dropdown--countrycode-selector {
  border-right: 1px solid #dce0e5;
  min-width: 144px;
  width: 144px;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input-holder--phone-input {
  align-items: center;
  display: flex;
}
.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-phone-number {
  align-items: center;
  display: flex;
  flex: 3;
}
.adyen-checkout-phone-input--new .adyen-checkout-phone-input__error-holder {
  margin-top: -10px;
}