.adyen-checkout-trustly {
  margin-bottom: 16px;
}
.adyen-checkout-trustly__descriptor {
  margin: 0 0 4px;
  font-size: 1em;
  font-weight: 500;
}
.adyen-checkout-trustly__description-list {
  list-style-type: disc;
  margin: 0;
  font-size: 0.81em;
  line-height: 1.5;
  padding-left: 20px;
}