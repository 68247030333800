.adyen-checkout__alert-message {
  display: flex;
  border-radius: 6px;
  margin: 0 0 16px;
  text-align: left;
  padding: 12px;
  font-size: 0.81em;
}

.adyen-checkout__alert-message--error {
  background: #fbe6ed;
}

.adyen-checkout__alert-message--warning {
  background: #ffeacc;
}

.adyen-checkout__alert-message--info {
  background: #e5efff;
}

.adyen-checkout__alert-message__icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}