.adyen-checkout__button {
  background: #00112c;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  height: 48px;
  margin: 0;
  padding: 15px;
  text-decoration: none;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 100%;
}
.adyen-checkout__button:focus {
  box-shadow: 0 0 0 2px #3070ED;
  outline: 0;
}
.adyen-checkout__button:hover {
  background: #1c3045;
  box-shadow: 0 0, 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
.adyen-checkout__button:active {
  background: #3a4a5c;
}
.adyen-checkout__button:hover:focus {
  box-shadow: 0 0 0 2px #3070ED, 0 3px 4px rgba(0, 15, 45, 0.2);
}
.adyen-checkout__button:disabled, .adyen-checkout__button:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
  user-select: all;
}
.adyen-checkout__button.adyen-checkout__button--loading {
  background: #687282;
  box-shadow: none;
  pointer-events: none;
  user-select: none;
}
.adyen-checkout__button.adyen-checkout__button--pay {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.adyen-checkout__button.adyen-checkout__button--pay:disabled {
  opacity: 0.4;
}
.adyen-checkout__button.adyen-checkout__button--standalone {
  margin-top: 0;
}
.adyen-checkout__button.adyen-checkout__button--inline {
  display: block;
  width: auto;
  height: auto;
  padding: 10px 8px;
  font-size: 0.81em;
}
.adyen-checkout__button.adyen-checkout__button--ghost {
  background: none;
  border: 0;
  color: #00112c;
}
.adyen-checkout__button.adyen-checkout__button--ghost:hover {
  background: #f7f8f9;
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--ghost:active {
  background: #e6e9eb;
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--secondary {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid #00112c;
  color: #00112c;
}
.adyen-checkout__button.adyen-checkout__button--secondary:hover {
  background: #f7f8f9;
  box-shadow: 0 2px 4px rgba(27, 42, 60, 0.2), 0 4px 5px rgba(27, 42, 60, 0.14);
}
.adyen-checkout__button.adyen-checkout__button--secondary:active, .adyen-checkout__button.adyen-checkout__button--secondary:active:hover {
  background: #f7f8f9;
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--secondary:disabled, .adyen-checkout__button.adyen-checkout__button--secondary:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  border-color: #99a3ad;
  background-color: #f7f8f9;
  opacity: 0.5;
  user-select: all;
}
.adyen-checkout__button.adyen-checkout__button--secondary .adyen-checkout__spinner {
  border-color: #00112c;
  border-top-color: transparent;
}
.adyen-checkout__button.adyen-checkout__button--action {
  padding: 10px 12px;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid transparent;
  color: #0075ff;
}
.adyen-checkout__button.adyen-checkout__button--action:hover {
  background: rgba(0, 102, 255, 0.2);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--action:active, .adyen-checkout__button.adyen-checkout__button--action:active:hover {
  background: rgba(0, 102, 255, 0.3);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--link {
  background: transparent;
  border: 1px solid transparent;
  color: #0075ff;
  font-weight: 400;
  border-radius: 3px;
  padding: 2px;
}
.adyen-checkout__button.adyen-checkout__button--link:hover {
  background: transparent;
  text-decoration: underline;
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--completed, .adyen-checkout__button.adyen-checkout__button--completed:hover, .adyen-checkout__button.adyen-checkout__button--completed:active, .adyen-checkout__button.adyen-checkout__button--completed:active:hover {
  background: #089a43;
  color: #fff;
}
.adyen-checkout__button.adyen-checkout__button--completed .adyen-checkout__button__icon {
  filter: brightness(0) invert(1);
}
.adyen-checkout__button__content {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.adyen-checkout__button__icon {
  margin-right: 12px;
}
[dir=rtl] .adyen-checkout__button__icon {
  margin-right: 0;
  margin-left: 12px;
}
.adyen-checkout__button__text {
  display: block;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adyen-checkout__button .adyen-checkout__spinner {
  border-color: #fff;
  border-top-color: transparent;
}