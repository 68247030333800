.adyen-checkout__button-group {
  background: transparent;
  display: flex;
  justify-content: space-between;
}
.adyen-checkout__button-group .adyen-checkout__button {
  background: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px #99a3ad;
  color: #00112c;
  font-size: 0.81em;
  font-weight: normal;
  line-height: 40px;
  margin-right: 8px;
  height: 40px;
  padding: 0;
  text-align: center;
}
.adyen-checkout__button-group .adyen-checkout__button:last-child {
  margin-right: 0;
}
.adyen-checkout__button-group .adyen-checkout__button:hover {
  background: transparent;
  box-shadow: inset 0 0 0 2px #99a3ad;
}
.adyen-checkout__button-group .adyen-checkout__button:active {
  background: #f7f8f9;
  box-shadow: inset 0 0 0 2px #99a3ad;
}
.adyen-checkout__button-group .adyen-checkout__button--disabled, .adyen-checkout__button-group .adyen-checkout__button--disabled:hover {
  cursor: not-allowed;
  opacity: 0.4;
  user-select: none;
}
.adyen-checkout__button-group .adyen-checkout__button--selected, .adyen-checkout__button-group .adyen-checkout__button--selected:hover, .adyen-checkout__button-group .adyen-checkout__button--selected:active, .adyen-checkout__button-group .adyen-checkout__button--selected:active:hover {
  background: #e5efff;
  box-shadow: inset 0 0 0 2px #0075ff;
  color: #0075ff;
  font-weight: 500;
  height: 40px;
  transition: none;
}
.adyen-checkout__button-group .adyen-checkout__button .adyen-checkout__button-group__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}