.adyen-checkout__bacs--confirm {
  position: relative;
}
.adyen-checkout__bacs--confirm .adyen-checkout-input__inline-validation--valid {
  display: none;
}
.adyen-checkout__bacs .adyen-checkout__field--inactive {
  pointer-events: none;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit {
  position: absolute;
  top: -25px;
  right: 0;
  cursor: pointer;
  width: 20%;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit-dropin {
  top: -50px;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit .adyen-checkout__bacs--edit-button {
  border: none;
  background: none;
  color: #0075ff;
  text-decoration: underline;
  text-align: end;
  cursor: pointer;
}