.adyen-checkout__status {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin: 0;
  padding: 32px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #d4d9db;
  font-size: 1em;
  color: #00112c;
}
.adyen-checkout__status__icon {
  margin-bottom: 24px;
}
.adyen-checkout__status .adyen-checkout__spinner__wrapper {
  max-height: 88px;
}