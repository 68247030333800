.adyen-checkout__ctp-modal-header-image {
  display: block;
  margin: auto;
}
.adyen-checkout__ctp-modal-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 0;
  margin: 0 0 12px;
}
.adyen-checkout__ctp-modal-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 16px;
}
.adyen-checkout__ctp-modal-benefits {
  margin-left: 0;
  padding-left: 20px;
}
.adyen-checkout__ctp-modal-benefits li {
  margin-bottom: 16px;
  list-style: disc;
}

.adyen-checkout__modal-wrapper--ctp .adyen-checkout__modal {
  max-width: 464px;
}

.adyen_checkout-ctp__brand-wrapper--popup {
  justify-content: center;
  margin-bottom: 24px;
}