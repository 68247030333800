.adyen-checkout-ctp__otp-checkbox-container {
  padding: 12px;
  background-color: #f7f8f9;
  border-radius: 6px;
  margin-bottom: 16px;
}

.adyen-checkout-ctp__otp-checkbox-container--checked {
  background-color: #F2F8FF;
}

.adyen-checkout-ctp__otp-checkbox-container label {
  cursor: pointer;
}

.adyen-checkout-ctp__section .adyen-checkout__field.adyen-checkout-ctp__otp-checkbox-container {
  margin-bottom: 4px;
}

.adyen-checkout-ctp__otp-checkbox-info {
  color: #707070;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  margin: 4px 0 0;
}

.adyen-checkout-ctp__otp-readmore-button {
  all: unset;
  text-transform: lowercase;
  cursor: pointer;
  color: #0075ff;
}