.adyen-checkout__bankTransfer__introduction {
  font-size: 0.81em;
  color: #00112c;
  font-weight: 400;
  margin: 0 0 16px;
  padding: 0;
}

.adyen-checkout__bankTransfer__emailField {
  margin: 0 0 16px;
}