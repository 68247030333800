.adyen-checkout-payme-instructions {
  font-size: 0.81em;
  text-align: center;
  color: #5c687c;
  line-height: 20px;
}
.adyen-checkout-payme-instructions__steps {
  list-style-position: inside;
  padding-inline-start: 0;
  margin: 16px 0;
  padding-bottom: 8px;
}