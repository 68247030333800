.card-input__wrapper {
  position: relative;
}
.card-input__wrapper *, .card-input__wrapper *::before, .card-input__wrapper *::after {
  box-sizing: border-box;
}
.card-input__icon {
  border-radius: 3px;
  position: absolute;
  right: 10px;
  margin-left: 7px;
  transform: translateY(-50%);
  top: 50%;
  height: 18px;
  width: 27px;
}
.card-input__form {
  opacity: 1;
}
.card-input__spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}
.card-input__spinner--active {
  display: block;
}
.card-input__form--loading {
  opacity: 0;
}

.adyen-checkout__input {
  display: block;
  max-height: 100px;
}

.adyen-checkout__card__cvc__input--hidden,
.adyen-checkout__card__exp-date__input--hidden {
  display: none;
}

.adyen-checkout__card__exp-cvc__exp-date__input--hidden {
  justify-content: flex-end;
}

.revolving-plan-installments__disabled {
  pointer-events: none;
  opacity: 0.4;
}