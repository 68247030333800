.adyen-checkout__radio_group + .adyen-checkout-input__inline-validation {
  display: none;
}

.adyen-checkout__radio_group__input {
  opacity: 0;
  position: absolute;
}

.adyen-checkout__radio_group__label {
  padding-bottom: 0;
  padding-left: 24px;
  position: relative;
  display: block;
  color: inherit;
  font-size: 0.81em;
  font-weight: normal;
  line-height: 16px;
  overflow: visible;
}

.adyen-checkout__label--focused .adyen-checkout__radio_group__label {
  color: inherit;
}

.adyen-checkout__radio_group__label::before {
  content: "";
  position: absolute;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  left: 0;
  top: 0;
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}

.adyen-checkout__radio_group__label:hover::before {
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
  cursor: pointer;
}

.adyen-checkout__radio_group__label::after {
  content: "";
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 5px;
  top: 5px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.2s ease-out;
  box-shadow: 0 1px 1px rgba(0, 15, 45, 0.25);
}

.adyen-checkout__radio_group__label:hover {
  border-color: #0075ff;
  cursor: pointer;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label::before,
.adyen-checkout__radio_group__label--selected {
  background-color: #0075ff;
  border: 0;
  transition: all 0.2s ease-out;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label::after {
  transform: scale(1);
}

.adyen-checkout__radio_group__input:focus + .adyen-checkout__radio_group__label::before {
  border-color: #0075ff;
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:hover::before,
.adyen-checkout__radio_group__input:checked:focus + .adyen-checkout__radio_group__label::before,
.adyen-checkout__radio_group__input:checked:active + .adyen-checkout__radio_group__label::before {
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
}

.adyen-checkout__radio_group__label.adyen-checkout__radio_group__label--invalid::before {
  border: 1px solid #c12424;
}