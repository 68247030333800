.adyen-checkout-sr-panel {
  margin-bottom: 20px;
  font-size: 0.75em;
}

.adyen-checkout-sr-panel--sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  position: absolute;
}